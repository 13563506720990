/** Libs */
import { Fragment, useState } from "react"
/** Global Components */
/** Local Components */

/** Assets */
import dailyInsightLottie from 'assets/lottie/home/home-daily-insight.json';
/** Utils */
import { useScrollAnim } from "components/hooks/hooks"
import { Swiper, SwiperSlide } from "swiper/react"
import Lottie from "react-lottie"

export default function HomeDailyInsight({ data, setState }) {

  // Hooks
  const [ref, anim] = useScrollAnim()
  // Func

  // Use Effect

  const sparkStyles = {
    0: {
      position: "absolute",
      left: "-2.5rem",
      bottom: "2rem",
    },
    1: {
      position: "absolute",
      left: "-1.5rem",
    },
    2: {
      position: "absolute",
      left: "-.5rem",
      top: "1.5rem",
    },
    3: {
      position: "absolute",
      left: "-.5rem",
      bottom: "1rem",
    },
    4: {},
    5: {
      position: "relative",
      top: "-1rem",
    },
    big: {
      width: 99, height: 99
    }
  }

  //state
  const [openedStories, openedStoriesSet] = useState([]);

  return (
    <section ref={ref} className='sc-home-daily-insight overflow-hidden'>
      <div className='py-main mw-xxl mx-auto position-relative d-md-flex overflow-hidden sc-home-daily-insight-inside row-lg'>
        <div className='d-flex justify-content-between justify-content-lg-start container px-md-0 align-items-md-start heading-title col-lg-4'>
          <h3 className={`m-0 text-36-60 font-semibold ${anim(1)}`}>Daily <br />Insights</h3>
          <div className='d-flex position-relative'>
            <div className="d-lg-block d-none" style={{transform:'translateY(-50px) translateX(-50px)'}}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: dailyInsightLottie,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                width={190}
                isClickToPauseDisabled={true}
                style={{ margin: 0 }}
              />
            </div>
            <div className="d-lg-none position-absolute" style={{right:-20,top:0}}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: dailyInsightLottie,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice"
                  }
                }}
                width={144}
                isClickToPauseDisabled={true}
                style={{ margin: 0 }}
              />
            </div>
          </div>
        </div>

        <div className="story-line mt-4 col-lg-8 py-3">
          <Swiper
            breakpoints={{
              320: {
                spaceBetween: 14,
                slidesPerView: "auto",
              },
              768: {
                spaceBetween: 44,
                freeMode: true,
                slidesPerView: 5,
              },
            }}
            allowTouchMove
            style={{ paddingLeft: 24, paddingRight: 24 }}
            grabCursor
          >
            {data.map((item, key) => (
              item?.NewsStoryDetail?.dailyInsights?.thumbnail?.mediaItemUrl &&
              <Fragment key={`swiper-${key}`}>
                <SwiperSlide className="d-md-none"
                  onClick={() => {
                    setState({ isOpenStory: true, startFrom: key });
                    openedStoriesSet(prev => [...prev, item?.title])
                  }}>
                  <div
                    className={`story-line-container d-flex flex-column align-items-center ${anim(
                      key + 2
                    )}`}
                  >
                    <div className={`story-line-item mb-2 ${openedStories.includes(item?.title) && 'opened'}`}>
                      <div className="story-line-img">
                        <img src={item?.NewsStoryDetail?.dailyInsights?.thumbnail?.mediaItemUrl} />
                      </div>
                    </div>
                    <span className="text-center text-14-18">{item?.title}</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  style={{
                    transform: `translateY(${key % 2 !== 0 ? "44px" : "0px"})`,
                  }}
                  className="d-md-block d-none pb-3"
                  onClick={() => {
                    setState({ isOpenStory: true, startFrom: key })
                    openedStoriesSet(prev => [...prev, item?.title])
                  }}
                >
                  <div
                    className={`story-line-container d-flex pointer flex-column align-items-center ${anim(
                      key + 2
                    )}`}
                  >
                    <div className={`story-line-item mb-2 ${openedStories.includes(item?.title) && 'opened'}`}>
                      <div className="story-line-img">
                        <img src={item?.NewsStoryDetail?.dailyInsights?.thumbnail?.mediaItemUrl} />
                      </div>
                    </div>
                    <span className="text-center text-14-18">{item?.title}</span>
                  </div>
                </SwiperSlide>
              </Fragment>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}
