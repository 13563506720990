/** Libs */

/** Global Components */
import Subscription from "components/cak/subscription"

/** Local Components */

/** Assets */

/** Utils */

export default function HomeSubscription() {
  // State

  // Hooks

  // Func

  // Use Effect

  return (
    <section className="sc-home-subscription">
      <Subscription />
    </section>
  )
}
