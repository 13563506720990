/** Libs */
import Marquee from "react-fast-marquee"
/** Global Components */
import { ArrowBottom, ArrowUp } from "components/icons"

/** Local Components */

/** Assets */

/** Utils */
import { useScrollAnim } from "components/hooks/hooks"
import { useEffect, useState } from "react"
import axios from "axios"

export default function MarqueeStocks({ className, data }) {
  // State
  const [state, stateSet] = useState({
    data: [],
  })

  function setState(obj) {
    if (typeof obj !== "object") return
    stateSet((prev) => {
      return { ...prev, ...obj }
    })
  }

  // Hooks
  const [ref, anim] = useScrollAnim()
  // Func
  const getStocks = async () => {
    try {
      const { data } = await axios({
        url: process.env.NEXT_PUBLIC_MIDDLEWARE,
        method: "post",
      })
      if(typeof data !== "string") {
        setState({ data: data?.datas })
      }
      // console.log({ stocksData: data })
    } catch (error) {}
  }
  // Use Effect
  useEffect(() => {
    getStocks()
    // setTimeout(() => getStocksData(formData), 1000)
    // setInterval(() => getStocksData(formData), 8000)

    // return () => {
    //   clearInterval(getStocksData)
    //   clearTimeout(getStocksData)
    // }
  }, [])
  // console.log(state?.data)
  return (
    <section ref={ref} className={`sc-marquee-stocks ${className}`}>
      <Marquee gradient={false} className="marquee-container" speed={15}>
        {state?.data.map((item, key) => (
          <div key={key} className={`marquee-item ${anim(key + 1)}`}>
            <p className="m-0 text-16 font-bold mb-1">{item?.code}</p>
            <div className="d-flex align-items-end">
              <span className="text-16 font-semibold">{item?.price}</span>
              <div>
                {!item?.change.includes("-") ? (
                  <ArrowUp
                    width={20}
                    height={20}
                    fill={"#219653"}
                    className="mx-1"
                  />
                ) : (
                  <ArrowBottom
                    width={20}
                    height={20}
                    fill={"#FF3A3A"}
                    className="mx-1"
                  />
                )}
              </div>
              <span
                className={`text-14 ${
                  !item?.change.includes("-")
                    ? "text-cak-green"
                    : "text-cak-red"
                }`}
              >
                {item?.percentChange}
              </span>
            </div>
          </div>
        ))}
      </Marquee>
    </section>
  )
}

export const getServerSideProps = async () => {
  const getStocks = await axios({
    url: process.env.NEXT_PUBLIC_MIDDLEWARE,
    method: "post",
  })

  return {
    props: {
      data: getStocks,
    },
  }
}
