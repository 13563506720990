import { Swiper, SwiperSlide } from "swiper/react";
import tren from "assets/img/common/tren.png"
import bareksa from "assets/img/common/bareksa.png"
import appstore from "assets/img/common/appstore.png"
import playstore from "assets/img/common/playstore.png"
import trenLogo from "assets/img/common/tren-logo.png"
import Link from "next/link";
import { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "components/anti";
import QRCode from "react-qr-code";
import { IconClose, IconClose2 } from "components/icons";

const a = [
  {
    mockup: tren,
    body: "Download *Tren App* and use referral code from us: ‘*CAK*’ to get *exclusive benefits* from Whatsapp group and our community.",
  },
  {
    mockup: bareksa,
    body: "Download *Bareksa App* and use referral code from us: ‘*CAK*’ to get *exclusive benefits* from Whatsapp group and our community.",
  },
]

const starSymbolConverter = (string, tag) => {
  if (!string) return string
  const regex = /\*(.*?)\*/g
  const replacedString = string.replace(
    regex,
    tag ? `<${tag}>$1</${tag}>` : `<span>$1</span>`
  )
  return replacedString
}

export default function Referral({ data = [] }) {
  const [currentTab, currentTabSet] = useState(0)
  const [isModalOpened, isModalOpenedSet] = useState(null)
  const swiperRef = useRef(null)
  // console.log(data)
  return (
    <section className="referral">
      <div className="d-lg-none">
        <div className="container">
          <h1 className="referral-title">
            Get an Amazing Reward from Us!
          </h1>
        </div>

        <div className="d-flex referral-tabs mtc-4 container">
          {data.map((item, i) => (
            <div key={i} className={`referral-tab ${currentTab === i && "active"}`} onClick={() => swiperRef.current.slideTo(i)}>
              <span className="font-chakrapetch">{item?.name}</span>
            </div>
          ))}
        </div>

        <div className="container">
          <Swiper
            slidesPerView={1}
            spaceBetween={16}
            onSlideChange={(e) => {
              currentTabSet(e.activeIndex)
            }}
            onBeforeInit={swiperArg => swiperRef.current = swiperArg}
          >
            {data.map((item, i) => (
              <SwiperSlide key={i}>
                <div style={{ paddingTop: 40, paddingBottom: 40 }}>
                  <div>
                    <img src={item?.image?.mediaItemUrl} className="w-100 h-100" />
                  </div>
                  <p className="mt-4 mb-4 referral-body text-center" dangerouslySetInnerHTML={{ __html: starSymbolConverter(item?.benefit) }} />
                  <div className="d-flex" style={{ gap: 16 }}>
                    <Link href={item?.url?.appStore}>
                      <a target="blank">
                        <div>
                          <img src={appstore} className="w-100 h-100" />
                        </div>
                      </a>
                    </Link>
                    <Link href={item?.url?.googlePlaystore}>
                      <a target="blank">
                        <div>
                          <img src={playstore} className="w-100 h-100" />
                        </div>
                      </a>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className="d-none d-lg-flex align-items-center position-relative pb-5">
        <div className="container mw-xl">
          <div className="row">
            <div className="col-lg-6">
              <div className="position-relative">
                <img src={tren} className="position-relative" height={478} width={400} style={{ opacity: 0 }} />
                <AnimatePresence>
                  {currentTab === 0 && <motion.img initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} src={data[0]?.image?.mediaItemUrl} className="position-absolute" height={478} width={400} style={{ left: 0, top: 0 }} />}
                </AnimatePresence>
                <AnimatePresence>
                  {currentTab === 1 && <motion.img initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} src={data[1]?.image?.mediaItemUrl} className="position-absolute" height={478} width={400} style={{ left: 0, top: 0 }} />}
                </AnimatePresence>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="d-flex flex-column" style={{ gap: 24 }}>
                <h1 className="referral-title">Get an Amazing Reward from Us!</h1>
                <div className="row referral-tabs-desktop">
                  {data?.map((item, i) => (
                    <div key={i} className={`col-lg-6 referral-tab-desktop pointer ${currentTab === i && "active"}`} onClick={() => currentTabSet(i)}>
                      <span className="font-chakrapetch">{item?.name}</span>
                    </div>
                  ))}
                </div>

                <div className="position-relative">
                  <p className="referral-body" style={{ opacity: 0 }} dangerouslySetInnerHTML={{ __html: starSymbolConverter(data[0]?.benefit) }} />

                  <AnimatePresence>
                    {data.map((item, i) => (
                      currentTab === i &&
                      <motion.p key={i} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="referral-body position-absolute" style={{ left: 0, top: 0 }} dangerouslySetInnerHTML={{ __html: starSymbolConverter(item?.benefit) }} />
                    ))}
                  </AnimatePresence>
                </div>
              </div>
              <div className="d-flex" style={{ gap: 16, marginTop: 40, width: "55%" }}>
                <div className="pointer" onClick={() => isModalOpenedSet(data[currentTab]?.url?.appStore)}>
                  <img src={appstore} className="w-100 h-100" />
                </div>
                <div className="pointer" onClick={() => isModalOpenedSet(data[currentTab]?.url?.googlePlaystore)}>
                  <img src={playstore} className="w-100 h-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isModalOpened &&
          <motion.div initial={{ opacity: 0, y: 8 }} exit={{ opacity: 0, y: 8 }} animate={{ opacity: 1, y: 0 }} className="referral-modal-download">
            <div className="referral-modal-download-container">
              <div className="row">
                <div className="col-lg-5">
                  <QRCode
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={isModalOpened || "init"}
                  />
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-6 d-flex flex-column justify-content-between">
                  <div className="d-flex justify-content-end">
                    <div className="pointer" onClick={() => isModalOpenedSet(null)}>
                      <IconClose2 />
                    </div>
                  </div>
                  <div className="d-flex flex-column mt-3" style={{ gap: 16 }}>
                    <div>
                      <img src={trenLogo} width={104} />
                    </div>
                    <p className="referral-modal-body">Scan QR using phone’s camera <br />to Download Tren App</p>
                  </div>
                  <Button onClick={() => isModalOpenedSet(null)} className="btn-outline-light referral-btn-custom">OK</Button>
                </div>
              </div>
            </div>
          </motion.div>
        }
      </AnimatePresence>
    </section>
  )
}