/** Libs */

import { Button } from "components/anti"
import { IconChevronRight, IconLatestNews } from "components/icons"
import Link from 'next/link';
/** Global Components */

/** Local Components */

/** Assets */
import moment from "moment"
import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { linkNewsAnalysis } from "components/page_url"
import { useScrollAnim } from "components/hooks/hooks";
/** Utils */

export default function HomeLatestNews({ data }) {
  // State
  const [hoveredNews, hoveredNewsSet] = useState(0);
  // Hooks
  const [ref, anim] = useScrollAnim();
  // Func

  // Use Effect

  return (
    <section ref={ref} className="sc-home-latest-news">
      <div className="mw-xxl mx-auto container d-flex">
        <h3 className="font-semibold text-38-60">Latest News</h3>
        <div style={{ transform: "translateX(18px)" }} className="d-lg-none">
          <IconLatestNews />
        </div>
      </div>
      <div className="mw-xxl mx-auto container mt-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            {data.map((item, key) => (
              <Link href={`${linkNewsAnalysis}/${item?.slug}`} key={key}>
                <a>
                  <div
                    className={`"news-item d-flex align-items-center justify-content-between pb-3 pb-md-4 mb-3 mb-md-4 hover" ${anim(key)}`}
                    onMouseEnter={() => {
                      if (key !== hoveredNews) {
                        hoveredNewsSet();
                        setTimeout(() => hoveredNewsSet(key), 250);
                      }
                    }}
                  >
                    <div>
                      <p className="mb-1 text-12 text-white text-uppercase">{item?.topics?.nodes[0]?.name}</p>
                      <h3 className="m-0 mb-2 text-20 font-semibold text-white">
                        {item?.title}
                      </h3>
                      <p className="m-0 text-12 text-cak-white-70">{moment(item?.date).format("DD MMMM YYYY")}</p>
                    </div>
                    <div>
                      <IconChevronRight />
                    </div>
                  </div>
                </a>
              </Link>
            ))}
            <div className={`w-100 ${anim(data.length + 1)}`}>
              <Button link={linkNewsAnalysis} variant="outline-light" className="mt-4 w-100">
                BROWSE ALL NEWS
              </Button>
            </div>
          </div>
          <div className={`d-none d-md-block col-12 col-lg-6 ${anim(data.length + 2)}`}>
            <div className="latest-news-image mx-auto position-relative">
              <div className="icon-latest-news d-none d-lg-block">
                <IconLatestNews />
              </div>
              <AnimatePresence>
                {hoveredNews !== undefined && <motion.img initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} src={data[hoveredNews]?.news?.news?.image?.mediaItemUrl} className="img-full" />}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}