/** Libs */
import { IconClose } from "components/icons";
import Layout from "components/layout";
import { AnimatePresence, motion } from "framer-motion";
import { Helmet } from "react-helmet";
import InvestmentDetail from "../investment/investment-detail";

/** Global Components */

/** Local Components */

/** Assets */

/** Utils */

export default function Homeinvestment101Modal({ isModalDetailOpen = false, onClose, data }) {
  // State
  // Hooks
  // Func

  // Use Effect  
  return (
    <AnimatePresence>
      {isModalDetailOpen &&
        <>
          <Helmet>
            <body className="no-scroll" />
          </Helmet>
          <motion.section initial={{ opacity: 0, y: 15 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 15 }} className='sc-Homeinvestment101Modal w-100'>
            <Layout noBtnNavbar noFooter>
              <InvestmentDetail data={data || {}} />
            </Layout>

            <div className="hover icon-close" onClick={() => {
              onClose();
            }}>
              <IconClose />
            </div>
          </motion.section>
        </>
      }
    </AnimatePresence>
  )
}