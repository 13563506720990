// Library
import React, { useContext, useEffect, useRef, useState } from "react"
// Glpbal Component
import Seo from "components/seo"
import Story, { StoryRender } from "components/cak/story"
import Layout from "components/layout"
import MarqueeStocks from "components/cak/marquee-stocks"

// Local Components
import HomeSearchBanner from "components/pages/home/home-search-banner"
import HomeDailyInsight from "components/pages/home/home-daily-insight"
import HomeInvestment101 from "components/pages/home/home-investment-101"
import HomeBrowseByTopics from "components/pages/home/home-browse-by-topics"
import HomeLatestNews from "components/pages/home/home-latest-news"
import HomeSubscription from "components/pages/home/home-subscription"
import { LoadingContext } from "context/loading-context"
import { Helmet } from "react-helmet"
// Assets

import { getHome } from "lib/api/api"
import Referral from "components/cak/referral"

const HomePage = ({
  data,
  seo,
  dataBrowseByTopics,
  dataLatestNews,
  dataDailyInsight,
  dataInvestor101,
}) => {

  //state
  const [state, stateSet] = useState({
    isOpenStory: false,
    startFrom: 0,
    isModalTermsOpened: false,
    isModal101DetailOpened: false,
    activeStoryIndex: 0
  })
  //func
  const setState = (obj) => {
    if (typeof obj !== "object") return
    stateSet((prev) => {
      return { ...prev, ...obj }
    })
  }

  //hooks

  const swiperRef = useRef(null);

  const onlyDailyInsightData = dataDailyInsight.filter(
    (i) => i?.NewsStoryDetail?.dailyInsights?.thumbnail !== null
  )

  const onlyStoryDailyInsightData = onlyDailyInsightData.map((i, index) => {
    if (i?.NewsStoryDetail?.dailyInsights?.media)
      return i?.NewsStoryDetail?.dailyInsights?.media.map((ii, index2) => {
        return {
          slug: i?.title,
          content: ({ action, isPaused }) =>
            <StoryRender
              action={action}
              i={i}
              ii={ii}
              index={index}
              index2={index2}
              state={state}
              setState={setState}
              totalAllStory={onlyDailyInsightData.length - 1}
              totalStoryEach={i?.NewsStoryDetail?.dailyInsights?.media.length - 1}
              swiperRef={swiperRef}
              closeStory={() => setState({ isOpenStory: false })}
            />,
          duration: ii?.image?.mediaItemUrl ? 3000 : 15000
        }
      })
  })
  //log  

  return (
    <>
      <Seo title={seo?.title} description={seo?.metaDesc} />
      <Helmet>
        <body className="bd-home" />
      </Helmet>
      <Layout noBtnNavbar={state?.isModalTermsOpened || state?.isModal101DetailOpened} hasSearch hideMisc>
        <div className="home mt-4">
          <HomeSearchBanner data={data?.todaysPick} />
          <MarqueeStocks className="mt-4" />
          <HomeDailyInsight data={onlyDailyInsightData} setState={setState} />
          <HomeInvestment101
            data={data?.termOfTheDay}
            dataArticle={dataInvestor101}
            isModalTermsOpened={(val) => setState({ isModalTermsOpened: val })}
            isModal101DetailOpenedSet={val => setState({ isModal101DetailOpened: val })}
            isModal101DetailOpened={state?.isModal101DetailOpened}
          />
          <HomeBrowseByTopics data={dataBrowseByTopics} />
          <div className="sc-home-latest-news d-lg-none">
            <HomeLatestNews data={dataLatestNews.slice(0, 5)} />
          </div>
          <div className="sc-home-latest-news d-none d-lg-block">
            <HomeLatestNews data={dataLatestNews.slice(0, 3)} />
          </div>
          <HomeSubscription />          
          <Referral data={data?.partner} />
        </div>
      </Layout>

      <Story
        stories={onlyStoryDailyInsightData}
        isOpen={state.isOpenStory}
        closeStory={() => setState({ isOpenStory: false, activeStoryIndex: 0 })}
        from={state.startFrom}
        setState={setState}
        activeStoryIndex={state?.activeStoryIndex}
        swiperRef={swiperRef}
      />
    </>
  )
}

export default HomePage

export const getServerSideProps = async (ctx) => {
  const {
    data,
    seo,
    dataBrowseByTopics,
    dataLatestNews,
    dataDailyInsight,
    dataInvestor101,
  } = await getHome()
  return {
    props: {
      data,
      seo,
      dataBrowseByTopics,
      dataLatestNews,
      dataDailyInsight,
      dataInvestor101,
    },
  }
}
