/** Libs */

import { useScrollAnim } from "components/hooks/hooks"
import { linkNewsAnalysis } from "components/page_url"
import Link from "next/link"

/** Global Components */

/** Local Components */

/** Assets */

/** Utils */

export default function HomeBrowseByTopics({ data }) {
  // State

  // Hooks
  const [ref, anim] = useScrollAnim();
  // Func

  // Use Effect

  return (
    <section ref={ref} className='sc-browse-by-topics py-5 mb-4'>
      <div className="mw-xxl mx-auto px-4">
        <div className={`d-md-flex align-items-md-center justify-content-md-center ${anim(1)}`}>
          <h3 className="text-40-60 text-cak-red m-0">Browse</h3>
          <h4 className="text-24-44 font-normal m-0 ml-md-2">by Topics</h4>
        </div>
        <div className="sc-browse-by-topics-list d-flex mt-4 justify-content-md-center">
          {data.map((item, key) => (
            <Link href={`${linkNewsAnalysis}/topic/${item?.slug}`} key={key}>
              <a>
                <div className={`sc-browse-by-topics-list_item font-chakrapetch ${anim(key)}`}>
                  {item?.name}
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

// const allTopics = [
//   {
//     label: 'All',
//     link: '/'
//   },
//   {
//     label: 'Index Funds',
//     link: '/'
//   },
//   {
//     label: 'Stocks',
//     link: '/'
//   },
//   {
//     label: 'Mutual Funds',
//     link: '/'
//   },
//   {
//     label: 'Analysis',
//     link: '/'
//   },
//   {
//     label: 'IPO',
//     link: '/'
//   },
//   {
//     label: 'Cryptocurrency',
//     link: '/'
//   },
//   {
//     label: 'NFT',
//     link: '/'
//   },
//   {
//     label: 'Tax',
//     link: '/'
//   },
//   {
//     label: 'Macro Economy',
//     link: '/'
//   },
//   {
//     label: 'ETFs',
//     link: '/'
//   },
//   {
//     label: 'Investor Stories',
//     link: '/'
//   },
// ]