import { Breadcrumb, Image } from "components/anti"
import { useScrollAnim } from "components/hooks/hooks"
import React from "react"
import imgPlay from "assets/img/grfx/play.svg"
import { useState } from "react"
import { useRef } from "react"
import { linkHome, linkInvestment } from "components/page_url"
import iconOutline from "assets/img/grfx/navbar-outline.svg"
import iconRed from "assets/img/grfx/navbar-red.svg"
import iconStar from "assets/img/grfx/navbar-start-yellow.svg"

export default function InvestmentDetail({ data }) {
  //Utils ************************************************************************************************** //
  const [ref, anim] = useScrollAnim()
  const videoRef = useRef()
  //Utils ************************************************************************************************** //

  //States ************************************************************************************************* //
  const [state, setState] = useState({
    playVideo: false,
  })
  //States ************************************************************************************************* //

  //Functions ********************************************************************************************** //
  const handlePlay = () => {
    if (videoRef.current?.paused) {
      setState((prev) => ({ ...prev, playVideo: true }))
      videoRef.current?.play()
    } else {
      setState((prev) => ({ ...prev, playVideo: false }))
      videoRef.current?.pause()
    }
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: "interaction",
    //     eventCategory: "button clicks",
    //     eventAction: "/",
    //     eventLabel: `#wtf22 official trailer`,
    //   },
    // })
  }
  //Functions ********************************************************************************************** //

  //React Operations *************************************************************************************** //
  //React Operations *************************************************************************************** //

  return (
    <section className="sc-investment-detail" ref={ref}>
      <Image
        src={iconOutline}
        alt="outline"
        className="img-misc misc-outline"
      />
      <Image src={iconRed} alt="outline" className="img-misc misc-red" />
      <Image src={iconStar} alt="outline" className="img-misc misc-star" />
      <div className="container mw-xl">
        <Breadcrumb
          path={[
            { text: "Home", url: linkHome },
            { text: "Investment 101", url: linkInvestment },
            // { text: data?.title },
          ]}
          theme="dark"
          className="mb-2"
        />
        <h2 className={`title ${anim(1)}`}>{data?.title}</h2>
      </div>
      {data?.Investors101?.article?.video?.mediaItemUrl ? (
        <div className={`container mw-lg-xl container-video ${anim(2)}`}>
          <video
            className="video-content img-fluid"
            ref={videoRef}
            controls={state.playVideo}
            preload="metadata"
            playsInline
            onPlay={(e) => {
              let el = e.target
              if (el.requestFullscreen) {
                el.requestFullscreen()
              } else if (el.msRequestFullscreen) {
                el.msRequestFullscreen()
              } else if (el.mozRequestFullScreen) {
                el.mozRequestFullScreen()
              } else if (el.webkitRequestFullscreen) {
                el.webkitRequestFullscreen()
              }
            }}
          >
            <source
              src={`${data?.Investors101?.article?.video?.mediaItemUrl}#t=0.5`}
              type="video/mp4"
            />
          </video>
          <Image
            src={imgPlay}
            alt="play button"
            className={`img-play img-fluid ${state.playVideo ? "d-none" : ""}`}
            onClick={() => handlePlay()}
          />
        </div>
      ) : null}
      <div className="container mw-xl">
        <div
          className={`content ${anim(3)} text-14 img-full-inside`}
          dangerouslySetInnerHTML={{
            __html: data?.Investors101?.article?.text,
          }}
        />
      </div>
    </section>
  )
}
