/** Libs */
import { useScrollAnim } from "components/hooks/hooks"
import { useEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import Link from "next/link"
/** Global Components */
import { Button, Video, Link as LinkAnti, Breadcrumb } from "components/anti"
import {
  IconArrowLeft,
  IconArrowRight,
  IconChevronRight,
  IconClose,
  IconPlay,
  IconShare2,
} from "components/icons"

/** Local Components */

/** Assets */
import homeInvestmentLottie from "assets/lottie/home/home-investment-101.json"
import circleOutline from "assets/img/icon/investment-101/circle-outline.svg"
import star from "assets/img/icon/investment-101/star.svg"
import circleYellow from "assets/img/icon/investment-101/circle-yellow.svg"
import quotesIcon from "assets/img/icon/investment-101/quotes.png"
import { linkGlossary, linkHome, linkNewsAnalysis } from "components/page_url"
import Layout from "components/layout"
import { AnimatePresence, motion } from "framer-motion"
import CopyToClipboard from "react-copy-to-clipboard"
import { useRouter } from "next/router"
import { toast } from "react-toastify"
import Homeinvestment101Modal from "./home-investment101-modal"
import { Helmet } from "react-helmet"
import quotesImg from "assets/img/icon/misc/quote.svg"
import yellowStar from "assets/img/grfx/sparks/spark-four-yellow.png"
import redIcon from "assets/img/grfx/star-red.png"
import ringIcon from "assets/img/grfx/blue-ring.png"
import Lottie from "react-lottie"
/** Utils */

export default function HomeInvestment101({
  data = [],
  dataArticle = [],
  isModalTermsOpened,
  isModal101DetailOpened,
  isModal101DetailOpenedSet,
}) {
  // State
  const [isModalOpen, isModalOpenSet] = useState(false)

  const [dataDetail, dataDetailSet] = useState()
  // Hooks
  const [ref, anim] = useScrollAnim()
  const swiperRef = useRef(null)
  // Hooks
  const router = useRouter()
  // const termTitle = router?.query?.term.split("-").join(" ");
  // Func

  // Use Effect

  const decorPosition = {
    0: {
      top: "-3rem",
    },
    1: {},
    2: {
      top: "-2rem",
    },
  }

  const observer = new IntersectionObserver((entries) => {
    // Loop over the entries
    entries.forEach((entry) => {
      // If the element is visible
      if (entry.isIntersecting) {
        // Add the animation class
        entry.target.classList.add("blink-fast")
      }
    })
  })

  useEffect(() => {
    observer.observe(document.querySelector(".the-lamp"))
  }, [])

  useEffect(() => {
    if (router?.query?.totd) {
      isModalOpenSet(true)
      isModalTermsOpened(true)
    }
  }, [router])

  // useEffect(() => {
  //   if (isModalOpen) {
  //     document.body.classList.add('no-scroll');
  //   } else {
  //     document.body.classList.remove('no-scroll');
  //   }
  // }, [isModalOpen])

  const breadcrumb = [
    {
      text: "Home",
      url: linkHome,
    },
    {
      text: "News & Analysis",
      url: linkNewsAnalysis,
    },
    {
      text: data[0]?.Glossary?.glossary?.term,
      url: "",
    },
  ]

  return (
    <>
      <section
        ref={ref}
        className="sc-home-investment-101 py-4 position-relative"
      >
        <div className="d-flex position-absolute decoration">
          {[circleYellow, star, circleOutline].map((item, key) => (
            <img
              className={`position-relative ${anim(key)}`}
              style={{ ...decorPosition[key] }}
              src={item}
              key={`circle-${key}`}
            />
          ))}
        </div>
        <div className="sc-home-investment-101-container py-main pb-3 mw-xxl mx-auto position-relative d-md-flex overflow-hidden">
          <div className="sc-home-investment-101-inside px-4">
            <div className="d-flex justify-content-between align-items-md-start position-relative">
              <div>
                <div className={`bubble-chat mb-1 ${anim(4)}`}>
                  <h6 className="font-semibold text-14">New to Investment?</h6>
                </div>
                <h3 className={`m-0 text-36-60 font-semibold ${anim(5)}`}>
                  Investment <br />
                  101
                </h3>
                <Link href={linkGlossary}>
                  <a>
                    <Button
                      variant="link"
                      className={`text-cak-yellow text-14 ${anim(6)}`}
                    >
                      GLOSSARY TEXT
                      <IconArrowRight fill="#FFC850" className="ml-2" />
                    </Button>
                  </a>
                </Link>
              </div>
              <div
                className={`sc-home-investment-101-lamp d-flex position-absolute`}
              >
                <div className={`the-lamp w-100 h-100 m-0`}>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: homeInvestmentLottie,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    isClickToPauseDisabled={true}
                    style={{ margin: 0 }}
                  />
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                isModalTermsOpened(true)
                isModalOpenSet(true)
              }}
              className={`sc-home-investment-101-terms_of_the_day d-none d-md-flex hover`}
            >
              <div className={`quotes ${anim(7)}`}>
                <img src={quotesIcon} />
              </div>
              <div
                className={`terms-box d-flex align-items-center justify-content-between pr-3 ${anim(
                  8
                )}`}
              >
                <div>
                  <p className="m-0 text-12 text-cak-white-70">
                    TERM OF THE DAY
                  </p>
                  <h3 className="m-0 text-18 text-white font-semibold">
                    {data[0]?.title}
                  </h3>
                </div>
                <IconChevronRight />
              </div>
            </div>
          </div>

          <div className="sc-home-investment-101-list_101 d-none d-md-block">
            {/* arrow */}
            <div className="arrow-desktop d-flex align-items-center justify-content-end position-absolute w-100">
              <Button
                onClick={() => swiperRef.current.slidePrev()}
                variant="link"
                className="mr-3"
              >
                <IconArrowLeft width={44} height={44} />
              </Button>
              <Button
                onClick={() => swiperRef.current.slideNext()}
                variant="link"
              >
                <IconArrowRight width={44} height={44} />
              </Button>
            </div>
            {/* end: arrow */}

            <Swiper
              slidesPerView={4}
              spaceBetween={32}
              grabCursor
              onBeforeInit={(swiper) => (swiperRef.current = swiper)}
            >
              {dataArticle.map((item, key) => (
                <SwiperSlide key={key} className={`${anim(key + 2)}`}>
                  <div
                    className="sc-home-investment-101-list_101-item w-100"
                    onClick={() => {
                      isModal101DetailOpenedSet(true)
                      dataDetailSet(item)
                    }}
                  >
                    <div className="thumbnail">
                      {item?.Investors101?.article?.thumbnailImage
                        ?.mediaItemUrl ? (
                        <img
                          src={
                            item?.Investors101?.article?.thumbnailImage
                              ?.mediaItemUrl
                          }
                          className="img-full"
                        />
                      ) : (
                        <Video
                          videoSrc={
                            item?.Investors101?.article?.video?.mediaItemUrl
                          }
                          className="img-full"
                          loop={false}
                          preload="metadata"
                          autoPlay={false}
                          playsInline={false}
                          ratio={"r-16-9"}
                        />
                      )}
                    </div>
                    {/* Playbutton */}
                    <div className="box-title">
                      <LinkAnti
                        to={item?.Investors101?.article?.video?.mediaItemUrl}
                        video
                        controls
                      >
                        <IconPlay className="position-absolute" />
                      </LinkAnti>
                      <h6 className="text-16 m-0 text-white pointer font-weight-500">
                        {item?.title}
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="sc-home-investment-101-terms_of_the_day d-md-none">
          <div className="quotes">
            <img src={quotesIcon} />
          </div>
          <div className="terms-box d-flex align-items-center justify-content-between pr-3">
            <div
              className="d-flex align-items-center justify-content-between w-100"
              onClick={() => {
                isModalTermsOpened(true)
                isModalOpenSet(true)
              }}
            >
              <div>
                <p className="m-0 text-12 text-cak-white-70">TERM OF THE DAY</p>
                <h3 className="m-0 text-18 text-white font-semibold">
                  {data[0]?.title}
                </h3>
              </div>
              <IconChevronRight />
            </div>
          </div>
        </div>
        <div className="sc-home-investment-101-list_101 mt-3  d-md-none">
          <Swiper
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 16,
              },
            }}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            {dataArticle.map((item, key) => (
              <SwiperSlide key={key} className={`${anim(key + 1)}`}>
                <div
                  className="sc-home-investment-101-list_101-item w-100"
                  onClick={() => {
                    isModal101DetailOpenedSet(true)
                    dataDetailSet(item)
                  }}
                >
                  <div className="thumbnail">
                    {item?.Investors101?.article?.thumbnailImage
                      ?.mediaItemUrl ? (
                      <img
                        src={
                          item?.Investors101?.article?.thumbnailImage
                            ?.mediaItemUrl
                        }
                        className="img-full"
                      />
                    ) : (
                      <Video
                        videoSrc={
                          item?.Investors101?.article?.video?.mediaItemUrl
                        }
                        className="img-full"
                        loop={false}
                        preload="metadata"
                        autoPlay={false}
                        playsInline={false}
                        ratio={"r-16-9"}
                      />
                    )}
                  </div>
                  {/* Playbutton */}
                  <div className="box-title">
                    <LinkAnti
                      to={item?.Investors101?.article?.video?.mediaItemUrl}
                      video
                      controls
                    >
                      <IconPlay className="position-absolute" />
                    </LinkAnti>
                    <h6 className="text-16 m-0 text-white pointer font-weight-500">
                      {item?.title}
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>

      <AnimatePresence>
        {isModalOpen && (
          <>
            <Helmet>
              <body className="bd-home no-scroll" />
            </Helmet>

            <motion.div
              transition={{
                duration: 0.5,
                bounceDamping: 8,
                ease: "easeInOut",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="modal-terms-container bg-black"
            >
              <Layout noFooter noBtnNavbar>
                <div className="mw-xxl mx-auto container">
                  <Breadcrumb path={breadcrumb} theme="dark" className="m-0" />
                </div>

                {data[0]?.Glossary?.glossary?.image?.mediaItemUrl && (
                  <div className="img-misc">
                    <img
                      src={yellowStar}
                      style={{ transform: "translateY(-20px)" }}
                    />
                    <img src={redIcon} />
                    <img
                      src={ringIcon}
                      style={{ transform: "translateY(40px)" }}
                    />
                  </div>
                )}

                <section className="mw-xxl container overflow-auto position-relative">
                  <div className="quotes-grfx">
                    <img src={quotesImg} />
                  </div>
                  <div>
                    <p className="text-cak-yellow mb-1">TERM OF THE DAY</p>
                    <h3 className="text-30-36 m-0 font-semibold">
                      {data[0]?.Glossary?.glossary?.term}
                    </h3>
                  </div>

                  <div className="my-md-4 d-flex flex-column flex-lg-row">
                    <div
                      className="my-4 my-lg-0 col-lg-6 px-0"
                      style={{ height: "clamp(220px,61.11vw,440px)" }}
                    >
                      <img
                        className="img-full"
                        src={data[0]?.Glossary?.glossary?.image?.mediaItemUrl}
                        style={{
                          zIndex: 2,
                          position: "relative",
                          border: "1px solid black",
                        }}
                      />
                    </div>

                    <div
                      className="col-lg-6"
                      dangerouslySetInnerHTML={{
                        __html: data[0]?.Glossary?.glossary?.text,
                      }}
                    />
                  </div>
                </section>
              </Layout>

              <div className="d-flex justify-content-between align-items-center px-4 py-3 bottom-info w-100">
                <CopyToClipboard
                  onCopy={() => toast.success("Link copied",{toastId:"0_copy_hi"})}
                  text={`${process.env.NEXT_PUBLIC_SITE_URL}?totd=1`}
                >
                  <div className="hover">
                    <IconShare2 />
                  </div>
                </CopyToClipboard>
                <div
                  className="hover icon-close"
                  onClick={() => {
                    isModalOpenSet(false)
                    isModalTermsOpened(false)
                    router.push(linkHome, undefined, { shallow: true })
                  }}
                >
                  <IconClose />
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <Homeinvestment101Modal
        data={dataDetail}
        onClose={() => {
          isModal101DetailOpenedSet(false)
          setTimeout(() => dataDetailSet(), 800)
        }}
        isModalDetailOpen={isModal101DetailOpened}
      />
    </>
  )
}
