/** Libs */

/** Global Components */
import BannerSlider from "components/cak/banner-slider"
/** Local Components */

/** Assets */

/** Utils */


export default function HomeSearchBanner({data}) {
  const isIphoneUser = navigator.userAgent.includes("iPhone");

  // State

  // Hooks
  
  // Func

  // Use Effect

  return (
    <section className={`sc-home-search-banner ${isIphoneUser && 'iphone-height' }`}>      
      <div className="pb-4 bg-black">
        <BannerSlider data={data} />
      </div>
    </section>
  )
}